(function($){
$(document).ready(function() {

    var alert_mess = new Object();
    $.ajax({
        url: 'inc/xhr/actions.php?action=get_alerts_message',
            success: function(valeur) {
            alert_mess = JSON.parse(valeur);
        }
    });

    $('input[accept]').change(function() {
        verify($(this));
    });

    $('form').on('blur', '[data-verify]', function(){
        var elt = $(this);
        verify(elt);
    });

    $('body').on('change', 'input[data-verify="plusieurs_ja"]', function(e){
      var pja = $(this).val();
      console.log(pja);
      if(pja=="Oui") {
        e.preventDefault();
        var erreur = "";
        if($("#sel-activites").val().length==0 && $("#sel-competences").val().length==0)
          erreur = "Vous devez sélectionner au moins un centre d'intérêt et une compétence pour accepter d'accompagner d'autres JA";
        else if($("#sel-activites").val().length==0)
          erreur = "Vous devez sélectionner au moins un centre d'intérêt pour accepter d'accompagner d'autres JA";
        else if($("#sel-competences").val().length==0)
          erreur = "Vous devez sélectionner au moins une compétence pour accepter d'accompagner d'autres JA";
        if(erreur != "") {
          //$("#plusieurs_ja_Non input").remove();
          $("#plusieurs_ja_Oui input").prop( "checked" ,false);
          $("#plusieurs_ja_Oui").removeClass( "active");
          $("#plusieurs_ja_Non input").prop( "checked" ,true);
          $("#plusieurs_ja_Non").addClass( "active");
          alert(erreur);
        } else {
          return true;
        }
      }
    });

    $('body').on('change', 'input[data-verify="souhaite_acc"]', function(e){
      var sa = $(this).val();
      console.log(sa);
      if(sa=="Oui") {
        cp = $("#cp").val();
        if(cp=="") {
          alert('vous devez renseigner votre adresse (code postal) avant de pouvoir trouver un accompagnateur');
          $("#souhaite_acc_Oui input").prop( "checked" ,false);
          $("#souhaite_acc_Oui").removeClass( "active");
          $("#souhaite_acc_Non input").prop( "checked" ,true);
          $("#souhaite_acc_Non").addClass( "active");
          e.preventDefault();
        } else {
          acc2 = $(".form-acc2").data('idacc');
          $("#choisir_acc").html("");
          $.ajax({
                url: 'inc/xhr/actions.php?action=choisir_acc&cp='+cp+'&deja='+acc2,
                success: function(data) {
                    $("#choisir_acc").html(data);
                }
            })
        }
      }
    });

    $('body').on('change', 'input[data-verify="souhaite_acc2"]', function(e){
      var sa = $(this).val();
      console.log(sa);
      if(sa=="Oui") {
        cp = $("#cp").val();
        if(cp=="") {
          alert('vous devez renseigner votre adresse (code postal) avant de pouvoir trouver un accompagnateur');
          $("#souhaite_acc2_Oui input").prop( "checked" ,false);
          $("#souhaite_acc2_Oui").removeClass( "active");
          $("#souhaite_acc2_Non input").prop( "checked" ,true);
          $("#souhaite_acc2_Non").addClass( "active");
          e.preventDefault();
        } else {
          acc1 = $(".form-acc").data('idacc');
          $("#choisir_acc2").html("");
          $.ajax({
                url: 'inc/xhr/actions.php?action=choisir_acc&indice=2&cp='+cp+'&deja='+acc1,
                success: function(data) {
                    $("#choisir_acc2").html(data);
                }
            })
        }
      }
    });

    $('body').on('change', 'input[data-verify="date_naiss"]', function(){
        valeur = valeur_elt($(this));
        //console.log('valeur :'+valeur);
        age = quel_age(valeur);

        if($(this).parents('.fiche_representants').length == 1)
          type = 'rep';
        else
          type = 'membre';


        var hab = "JA";
        if($(this).parents("form").hasClass('JAM'))
          hab = "JAM";

        //console.log('age :'+age);
        if (age >5 && age < 11){
          if(type=="membre")
            alert('ce membre a moins de 11 ans, il vous faudra demander une dérogation à votre Relais Départemental');
          else {
            alert('les représentants doivent avoir 11 ans minimum');
            $(this).val("");
          }
        }

        if (age <=5){
            alert('ce membre a moins de 5 ans, impossible de l\'enregistrer');
            $(this).val("");
        }



        if (age >20){
            alert('ce membre a plus de 20 ans, impossible de l\'enregistrer :-/');
            $(this).val("");
        }
    });

    $('form').on('change', 'input[name="type"]', function(){
        inputDateNaiss = $('input[name="date_naiss"]');
        if (valeur_elt(inputDateNaiss) != false){
            //console.log(valeur_elt(inputDateNaiss));
            //console.log(valeur_elt($(this)));
            verify(inputDateNaiss);
        }
    });

    $('form').not('.form-validable').on('submit', function(){
        var form = $(this);
        if (test_form(form) === 0){
            return true;
        }
        else{
            affiche_erreurs();
            scrollTo(0,0);
            return false;
        }
    });

    var form_validable = $('.form-validable');

    var form_errors = new Array();

    $('#btn_valider_definitivement_form').on('click', function(){
        var alerte = $(this).data('alerte');
        if (test_form(form_validable) === 0){
          if(confirm(alerte)) {
            $(".validation_ja").val(1);
            form_validable.submit();
          }
        }
        else{
            affiche_erreurs();
            scrollTo(0,0);
        }
    });

    function valeur_elt(elt){
        var type = elt.attr('type');
        switch(type){
            case 'checkbox':
            case 'radio':
                name=elt.attr('name');
                val = $('input[name="'+name+'"]:checked').val();
                if (typeof val === "undefined") val=false;
            break;

            default:
                val = (elt.val() != '') ? elt.val() : false;
                break;
        }

        return val;
    }


    function affiche_erreur(msg, elt_for_span, elt_for_class){
        if (elt_for_class === undefined) elt_for_class = elt_for_span;
        var span_error = '<span class="field-error">' + msg + '</span>';
        elt_for_span.after(span_error);
        elt_for_class.addClass('has-error');
        form_errors.push({msg: msg, elt_name: elt_for_span.data('short-label')});
    }

    function affiche_erreurs(){
        $('.validation_erreurs').html('<ul></ul>');
        ul_errors = $('.validation_erreurs ul');
        $.each(form_errors, function(index,item){
            ul_errors.append('<li><strong>'+item.elt_name+' :</strong> '+item.msg+'</li>');
        })
        $('.validation_erreurs').show();
    }

    function email_valide(email) {
        var reg = new RegExp('^[a-z0-9]+([_\\-.+]{1}[a-z0-9]+)*@[a-z0-9]+([_|\\.|-]{1}[a-z0-9]+)*[\\.]{1}[a-z]{2,}$', 'i');
        return(reg.test(email));
    }

    function file_valide(elt) {
        var file_extension = elt.val().match(/\.([^\.]+)$/)[1].toLowerCase(),
            allowed_extensions = new RegExp(elt.attr('accept').replace(/,/g, "$|^").replace(/\./g, "").toLowerCase());
        if (file_extension.match(allowed_extensions)) {
            return true;
        } else {
            elt.val("");
            return false;
        };
    }

    function date_format_fr(ladate) {
        // Valide une string au format JJ/MM/AAAA
        // http://regexr.com/3gfcg
        // lister les séparateurs autorisés à la suite, exple : '/ -.'
        var sep = '/';
        var reg = new RegExp('^\\d{2}['+sep+']\\d{2}['+sep+']\\d{4}$');
        return reg.test(ladate);
    }

    function date_valide(ladate) {
        // Valide une date existante au format JJ/MM/AAAA en prenant en compte les années bissextiles
        // http://regexr.com/3gfbl
        // lister les séparateurs autorisés à la suite, exple : '/ -.'
        var sep = '/';
        var reg = new RegExp('^(((0[1-9]|[12][0-9]|3[01])['+sep+'](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)['+sep+'](0[469]|11)|(0[1-9]|1\\d|2[0-8])['+sep+']02)['+sep+']\\d{4}|29['+sep+']02['+sep+'](\\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$');
        return reg.test(ladate);
    }

    function cp_valide(cp) {
        var reg = new RegExp(/^[0-9]{5}$/);
        return(reg.test(cp));
    }

    function password_valide(pwd) {
        // http://regexr.com/3bfsi
        var reg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/);
        pwd=pwd.trim();
        return(reg.test(pwd));
    }

    function nom_formate(elt, nom) {
        elt.val(nom.toUpperCase());
    }

    function prenom_formate(elt, prenom) {
        $.ajax({
            url: 'inc/xhr/actions.php?action=prenom_formate&valeur='+prenom,
            success: function(valeur) {
                elt.val(valeur);
            }
        });
    }

    function quel_age(date_naissance) {
        // date_naissance au format JJ/MM/AAAA;
        var day = new Date(date_naissance.substr(-4), date_naissance.substr(3,2)-1, date_naissance.substr(0,2));
        var annee_naissance = day.getFullYear();
        var mois_naissance  = day.getMonth();
        var jour_naissance  = day.getDate();

        var today = new Date();
        var annee = today.getFullYear();
        var mois  = today.getMonth();
        var jour  = today.getDate();

        age = annee - annee_naissance;
        if ( mois_naissance > mois || (mois_naissance == mois && jour_naissance > jour)) {
            age--;
        }
        return age;
    }

    function checkRequired(elt){
        var isRequired = elt.data('required');
        has_error = false;
        valeur = valeur_elt(elt);
        if (isRequired && !valeur){
            has_error = true;
            msg_error = alert_mess.required;
        }
    }

    function verify(elt){
        var type = elt.data('verify'),
            isRequired = elt.data('required');

        has_error = false;
        valeur = valeur_elt(elt);

        if(elt.attr('type')=='radio'){
            elt.parents('.has-error').siblings('.field-error').remove();
            elt.parents('.has-error').removeClass('has-error');
        }
        else{
            elt.siblings('.field-error').remove();
            elt.removeClass('has-error');
        }

        if (valeur !== false){
            res = test_input(type, elt, valeur);
            has_error = res[0];
            msg_error = res[1];
            if (msg_error == '')
                msg_error = alert_mess[type];
        }
        else{
            if (type == 'pass2'){
                pass1 = elt.parents('form').find('[data-verify="pass"]').val();
                has_error = (pass1 != valeur);
                msg_error = alert_mess[type];
            }
            if (isRequired && !valeur && ((elt.attr('id')!='rapport_financier' && elt.attr('id')!='budget') || $('.fl-rapport_financier .fileinput').val()=="")){
                // console.log('elt : '+elt.attr('id'));
                has_error = true;
                msg_error = alert_mess.required;
            }
        }

        if (has_error) {
            elt_for_span = elt;
            if (elt.attr('type')=='radio')
                elt_for_span = elt.parents('div').first();
            affiche_erreur(msg_error, elt_for_span);
        }

        return has_error;
    }

    function test_input(type, elt, valeur) {
        erreur_msg = '';
        has_error = false;
        //console.log('type : '+type);
        switch(type){
            case 'nom':
                nom_formate(elt,valeur);
            break;
            case 'prenom':
                prenom_formate(elt,valeur);
            break;
            case 'cp':
                has_error = !cp_valide(valeur)
            break;
            case 'email':
                has_error = !email_valide(valeur)
            break;
            case 'date_naiss':
                if (has_error = !date_format_fr(valeur)){
                    erreur_msg = alert_mess.date_format_fr;
                }
                else if (has_error = !date_valide(valeur)){
                    erreur_msg = alert_mess.date_valide;
                }
                else {
                    var age = quel_age(valeur);
                    var isJAMajeure = elt.parents('form').is('.majeure');
                    if(!isJAMajeure)
                      isJAMajeure = elt.parents('form').is('.JAM');
                    //console.log("isJAMajeure : "+isJAMajeure);
                    var isMembre = elt.parents('.liste_fiches').is("#membres");
                    if(!isMembre && $('input[name="type"]:checked').val()=="Membre")
                      isMembre = true;
                    var isRepresentant = elt.parents('.liste_fiches').is("#representants");
                    if(!isRepresentant && $('input[name="type"]:checked').val()=="Representant")
                      isRepresentant = true;
                    //console.log("isRepresentant : "+isRepresentant);
                    if (isMembre && (has_error = (age < 5 || age > 21))){
                        erreur_msg = alert_mess.age_membre;
                    }
                    else if (isRepresentant && !isJAMajeure && (has_error = (age < 5 || age >= 18))){
                        erreur_msg = alert_mess.age_repres;
                    }
                    else if (isRepresentant && isJAMajeure && (has_error = (age < 18 || age > 20))){
                        erreur_msg = alert_mess.age_repres_ja_majeure;
                    }
                    //console.log('erreur_msg : '+erreur_msg+age);
                }
            break;
            case 'pass':
                has_error = !password_valide(valeur)
            break;
            case 'pass2':
                var pwd = elt.parents('form').find('input[name="pass"]').val();
                has_error = !(valeur === pwd);
            break;
            case 'pj[logo]':
                if (has_error = !file_valide(elt)){
                    erreur_msg = alert_mess.file;
                }
            break;
            default:
                has_error = false;
        }

        return new Array(has_error, erreur_msg);
    }

    function test_form(form) {
        var nb_erreur = 0;
        form_errors = new Array();
        form.find('[data-verify]').each(function(){
            var elt = $(this);
            if (verify(elt)!=false) nb_erreur++;
        })
        if (form.is("#form-habilitation")) {

            if(form.hasClass("hab"))
              type = "hab";
            else if(form.hasClass("rehab"))
              type = "rehab";
            else if(form.hasClass("majeure"))
              type = "majeure";

            nb_repres = $(".fiche_representants").length;
            if (nb_repres < 2) {
                nb_erreur++;
                form_errors.push({msg: "Vous devez saisir au moins deux représentants", elt_name: 'Représentants'});
            }

            $(".Typoacc").each(function(index) {
                typo = $(this).find('select').last().val().substr(-1, 1);

                if(typo=="0") {
                    nb_erreur++;
                    form_errors.push({msg: "merci de préciser le type" , elt_name: 'Accompagnateur '+(index+1)});
                  }
              })

            nb_activite = $("#sel-activites option:selected").length;
            if (nb_activite == 0) {
                nb_erreur++;
                form_errors.push({msg: "Vous devez saisir au moins un type d'activité", elt_name: 'Activités'});
            }

            //Les vérifs qui manquent :
            // 1 - SI JA (si type != 'majeure')
            // Tous les représentants doivent être mineurs
            // Une majorité de membres (la moitié + 1 minimum) doivent être mineur
            // Si pas le cas :
            //    - Première hab : impossible
            //    - Réhab : Proposer une réhab en JAM

            // 2 - Si JAM
            // Pas de tests sur les ages des représentants
            // Une majorité de membres (la moitié + 1 minimum) doivent être majeurs
            var total_membres = 0;
            var nb_mineurs = 0;
            var nb_majeurs_sup = 0;
            var alert_rep_maj = false;
            $representants = $("#membresrepresentants").find(".fiche_representants");
            $representants.each(function(){
                var birthday = $(this).find('input[data-verify="date_naiss"]').val();
                var age = quel_age(birthday);
                //console.log(" age : " + age + " type : "+type);
                if(type != 'majeure' && age>=18) {
                  nb_erreur++;
                  alert_rep_maj=true;
                }
            });

            if(alert_rep_maj)
              form_errors.push({msg: "Les représentants d'une JA doivent tous être mineurs", elt_name: 'Représentants'});

            // var ages_cumules;
            $membres = $("#membresrepresentants").find(".fiche_membres");
            $membres.each(function(){
                total_membres++;
                var birthday = $(this).find('input[data-verify="date_naiss"]').val();
                var age = quel_age(birthday);
                if(age<18)
                  nb_mineurs++;
                if(age>20)
                  nb_majeurs_sup++;
            });


            // console.log('total_membres'+total_membres);
            // console.log('nb_mineurs'+nb_mineurs);

            if (nb_majeurs_sup > 0 ) {
              nb_erreur++;
              form_errors.push({msg: "Aucun membre de votre JA ne peut avoir plus de 20 ans", elt_name: 'Représentants et Membres'});
            }

            if (type != 'majeure' && nb_mineurs<= total_membres/2 ) {
              nb_erreur++;
              if(type == "hab")
                form_errors.push({msg: "Une majorité des membres d'une JA doivent être mineurs", elt_name: 'Représentants et Membres'});
              else
                form_errors.push({msg: "Une majorité des membres d'une JA doivent être mineurs, vous pouvez demander une réhabilitation en JA Majeure (étape 1 > votre projet)", elt_name: 'Représentants et Membres'});
            }


            if (type == 'majeure' && nb_mineurs > total_membres/2 ) {
                nb_erreur++;
                form_errors.push({msg: "Au moins la moitié des membres d'une JA Majeure doivent être majeurs", elt_name: 'Représentants et Membres'});
            }


            if ( ! $(".rd_id").is(':checked') ) {
              nb_erreur++;
              form_errors.push({msg: "Vous devez sélectionner un relais départemental", elt_name: 'Relais départemental'});
            }
        }

        return nb_erreur;
    }
}); // document.ready


})(jQuery);